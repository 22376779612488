<template>
<span
    @click="onClick"
    :class="btnClassProxy"
    :style="btnStyles"
>
    <span v-if="iconName" v-html="getIconByKey(iconName, {
            class: iconClassProxy,
            style: iconStyle
      })">
    </span>
    {{ $t(text) }}
  </span>
</template>

<script>
export default {
  name: "ButtonWithIcon",
  props: {
    iconName: {
      required: true,
    },
    iconClasses: {
      required: false,
      type: String,
      default: ''
    },
    iconStyle: {
      required: false,
      type: String,
      default: ''
    },
    btnClasses: {
      required: false,
      default: ''
    },
    btnStyles: {
      required: false,
      default: ''
    },
    size: {
      required: false,
      type: String,
      default: 'md',
      validation: function (value){
        return ['xs', 'sm', 'md', 'lg', 'xl'].indexOf(value) > -1;
      }
    },
    text: {
      required: false,
      type: String,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    active: {
      required: false,
      type: Boolean,
      default: false
    },

  },
  computed: {
    btnClassProxy(){
      let classes = 'btn-with-icon rounded-full d-flex justify-content-center items-center cursor-pointer green-border-1px';
      if(this.size === 'xs'){
        classes += ' px-2 py-0 text-xs'
      }
      else if(this.size === 'sm') {
        classes += ' px-2 py-1 text-sm'
      }else if(this.size === 'md'){
        classes += ' px-2 py-1 text-md'
      }else if(this.size === 'lg') {
        classes += ' px-4 py-1 text-lg'
      }else if(this.size === 'xl'){
        classes += ' px-5 py-1 text-xl'
      }
      if(this.disabled) {
        classes += ' opacity-45';
      }
      return classes + ' ' + this.btnClasses;
    },
    iconClassProxy() {
      let classes = '';
      if(this.size === 'xs'){
        classes += ' w-20px h-20px object-cover mr-1'
      }
      else if(this.size === 'sm'){
        classes += ' w-20px h-20px object-cover mr-1'
      }
      else if(this.size === 'md'){
        classes += ' w-25px h-25px object-cover mr-1'
      }
      else if(this.size === 'lg'){
        classes += ' w-30px h-30px object-cover mr-1'
      }
      else if(this.size === 'xl'){
        classes += ' w-40px h-40px object-cover mr-1'
      }
      if(!this.disabled) {
        classes += ' opacity-75';
      }
      return classes + ' ' + this.iconClasses;
    }
  },
  methods: {
    onClick(){
      if(this.active){
        this.$emit('onClick');
        return;
      }
      if(!this.disabled) {
        this.$emit('onClick')
      }
    }
  }
}
</script>

<style scoped>
</style>