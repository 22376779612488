<template>

  <div :class="{'form-inline d-flex': isInline, 'mb-3': hasBottomMargin}">
    <label
        :for="name"
        style="width: auto !important;"
        :class="labelClasses">
      {{ title }}
      <span class="text-danger" v-if="required">*</span>
      <info-tooltip
          :helper-text="helperText"
          v-if="helperText"
      ></info-tooltip>
    </label>
    <div :class="formGroupClasses">
      <input
          :class="inputClasses"
          :disabled="disabled"
          :id="name"
          :name="name"
          type="number"
          class="form-control w-100"
          :placeholder="placeholder"
          :required="required"
          v-model="modelProxy"
          :min="min"
          :max="max"
          :step="step"
          :style="{maxWidth: inputMaxWidth + ' !important', width: inputWidth + ' !important'}"
          @change="onChange"
      >
    </div>
    <div class="w-100" v-if="hint || error">
      <span class="form-text text-muted"
            :class="{'offset-lg-4 offset-md-4 offset-sm-0 col-lg-8 col-md-8 col-sm-12': isInline}">{{ hint }}</span>
      <p class="text-danger pt-1 px-3 block" :class="{'offset-lg-4 offset-md-4 col-lg-8 col-md-8 col-sm-12': isInline}"
         v-if="error">{{ $t(error) }}</p>
    </div>
  </div>
</template>

<script>
import InfoTooltip from "@/assets/components/widget/InfoTooltip";

export default {
  name: "NumberInput",
  props: {
    name: {},
    model: {},
    title: {},
    disabled: {
      required: false,
      default: false,
    },
    error: {},
    hint: {},
    placeholder: {},
    required: {
      default: true
    },
    min: {
      required: false,
      default: -Infinity,
    },
    max: {
      required: false,
      default: Infinity
    },
    step: {
      required: false,
      default: '0.01'
    },
    isInline: {
      required: false,
      default: true,
    },
    isValid: {
      required: false,
      default: null,
    },
    helperText: {
      default: null,
      required: false,
      type: String,
    },
    size: {
      default: 'sm',
      validator: function validator(l) {
        return ['xs', 'sm', 'md', 'lg'].indexOf(l) > -1;
      }
    },
    maxErrorMessage: {
      required: false,
      default: 'general.please_enter_valid_amount',
    },
    labelClass: {
      required: false,
      default: null
    },
    formGroupClass: {
      required: false,
      default: null,
    },
    inputMaxWidth: {
      required: false,
      default: '150px'
    },
    inputWidth: {
      required: false,
      default: 'w-auto',
    },
    hasBottomMargin: {
      required: false,
      default: true,
    },
    inputClass: {
      required: false,
      default: null,
    }
  },
  components: {
    InfoTooltip,
  },
  computed: {
    labelClasses() {
      let result = {
        'col-form-label-sm': this.size.toString() === 'sm',
        'col-form-label-lg': this.size.toString() === 'lg',
        'd-inline-block': this.isInline,
        'd-inline': !this.isInline,
      };

      if (Object(this.labelClass) === this.labelClass) {
        result = {...result, ...this.labelClass};
      } else if (Array.isArray(this.labelClass)) {
        this.labelClass.forEach(value => {
          result[value] = true
        })
      } else if (this.labelClass !== null) {
        result[this.labelClass] = true;
      }
      return result;
    },
    inputClasses() {
      let result = {
        'is-invalid': (this.isValid == null ? false : !this.isValid) && this.isValid === false,
        'form-control-sm': this.size.toString() === 'sm',
        'form-control-lg': this.size.toString() === 'lg',
        'is-valid': this.isValid === true && Number(this.modelProxy) !== 0 || this.modelProxy === '0',
      };
      if (Object(this.inputClass) === this.inputClass) {
        result = {...result, ...this.inputClass};
      } else if (Array.isArray(this.inputClass)) {
        this.inputClass.forEach(value => {
          result[value] = true
        })
      } else if (this.inputClass !== null) {
        result[this.inputClass] = true;
      }
      return result;
    },
    formGroupClasses() {
      let result = {
        'mx-3': this.isInline,
        'd-inline-block': this.isInline,
        'd-inline': !this.isInline,
      };

      if (Object(this.formGroupClass) === this.formGroupClass) {
        result = {...result, ...this.formGroupClass};
      } else if (Array.isArray(this.formGroupClass)) {
        this.formGroupClass.forEach(value => {
          result[value] = true
        })
      } else if (this.formGroupClass !== null) {
        result[this.formGroupClass] = true;
      }
      return result;
    },
    modelProxy: {
      get() {
        return this.model
      },
      set(value) {
        if (Number(value) > Number(this.max)) {
          this.error = this.maxErrorMessage;
          this.$emit('update:model', this.max + '')
        } else if (Number(value) < Number(this.min)) {
          this.error = this.maxErrorMessage;
          this.$emit('update:model', this.min + '')
        } else {
          this.$emit('update:model', value + '')
        }
      }
    }
  },
  methods: {
    onChange($event) {
      this.$emit('change', $event)
    }
  }
}
</script>


