<template>
  <div class="card card-custom pt-7 pb-10" style="max-height: 75vh;">
    <div v-if="transactions === null"
         class="card-body card-body-scroll w-100 h-200px d-flex justify-content-center items-center">
      <i class="fas fa-circle-notch fa-spin text-primary" style="font-size: 50px;"></i>
    </div>
    <div v-if="transactions !== null && transactions.length > 0" class="card-body card-body-scroll"
         style="overflow-y: scroll;max-height: 70vh;"
    >
      <div class="row">
        <div class="col-12 d-flex justify-content-center mb-2">
          <span class="text-3xl font-weight-boldest text-primary bottom-border-3px-primary">
          {{ $t('waybill.total_stock_amount') }} : 0
          </span>
        </div>
        <div class="col-12 d-flex justify-content-center">
          <span class="text-3xl font-weight-boldest ">
            {{ $t('waybill.total_order') }} : {{ totalAmount }} {{ amount_unit }}
            </span>
        </div>
        <div class="col-12 px-7 bottom-border-1px-navy w-100 h-auto"></div>

        <div class="col-12 d-flex justify-content-between mt-2">
          <span class="text-2xl font-weight-bold">
          {{ $t('waybill.total_income') }} : {{ Number(totalIncome) }} {{ amount_unit }}
          </span>
          <span class="text-2xl font-weight-bold">
          {{ $t('waybill.total_future_income') }} : {{ totalAmount - totalIncome }} {{ amount_unit }}
          </span>
        </div>

        <div class="col-12 d-flex justify-content-center mt-2">
          <span class="text-2xl font-weight-bold text-primary">
            {{ $t('waybill.total_exit') }} : {{
              Number(totalIncome) - Number(waybill.instructable ? 0 : 0)
            }} {{ amount_unit }}
            </span>
        </div>
        <div class="col-12 bottom-border-2px-navy w-100 h-auto my-2"></div>
      </div>
      <div v-for="(transaction, index) in transactions" :key="index" class="row">
        <div v-if="Number(transactionType) === 1" class="row col-5">
          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.depo_entry_date') }}</p>
          </div>
          <div class="col-5">
            <p class="text-lg">: {{ transaction.waybill_date | momentDateFormat }}</p>
          </div>

          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.company_name') }}</p>
          </div>
          <div class="col-5">
            <p class="text-lg">: {{ transaction.waybill.supplier_company.name }}</p>
          </div>

          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.order_no') }}</p>
          </div>

          <div class="col-5">
            <p class="text-lg">:
              {{ transaction.order_number }}
            </p>
          </div>

          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.product_name') }}</p>
          </div>
          <div class="col-5">
            <p class="text-lg">:
              {{ transaction.product_name }}
            </p>
          </div>

          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.product_color') }}</p>
          </div>
          <div class="col-5">
            <p class="text-lg">:
              {{ transaction.product_name }}
            </p>
          </div>

          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.tare_amount') }}</p>
          </div>
          <div class="col-5">
            <p class="text-lg">: {{ parseInt(transaction.tare_amount) }} {{ transaction.amount_unit }}</p>
          </div>

          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.gross_income_amount') }}</p>
          </div>
          <div class="col-5">
            <p class="text-lg">: {{ parseInt(transaction.gross_amount) }} {{ transaction.amount_unit }}</p>
          </div>

          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.net_income_amount') }}</p>
          </div>
          <div class="col-5">
            <p class="text-lg">: {{ parseInt(transaction.net_amount) }} {{ transaction.amount_unit }}</p>
          </div>

        </div>
        <div v-if="Number(transactionType) === 1" class="row col-5">
          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.waybill_type') }}</p>
          </div>
          <div class="col-5">
            <p class="text-lg">: {{ transaction.waybill.waybill_type.translations[0].name }}</p>
          </div>

          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.waybill_kind') }}</p>
          </div>
          <div class="col-5">
            <p class="text-lg">: {{ transaction.instruction_type.translations[0].name }}</p>
          </div>

          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.waybill_date') }}</p>
          </div>
          <div class="col-5">
            <p class="text-lg">: {{ transaction.waybill_date | momentDateFormat }}</p>
          </div>

          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.document_no') }}</p>
          </div>
          <div class="col-5">
            <p class="text-lg">: {{ transaction.document_number }}</p>
          </div>

          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.payment_plan') }}</p>
          </div>
          <div class="col-5">
            <p class="text-lg">: {{ transaction.instructable.expiry ? transaction.instructable.expiry.name : '' }} </p>
          </div>

          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.number_of_roll') }}</p>
          </div>
          <div class="col-5">
            <p class="text-lg">: {{ parseInt(transaction.fabric_roll) }}</p>
          </div>
          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.part_number') }}</p>
          </div>
          <div class="col-5">
            <p class="text-lg">: {{ transaction.part_number }}</p>
          </div>

        </div>

        <div v-if="Number(transactionType) === 0" class="row col-5">
          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.action_date') }}</p>
          </div>
          <div class="col-5">
            <p class="text-lg">: {{ transaction.waybill_date | momentDateFormat }}</p>
          </div>

          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.exit_type') }}</p>
          </div>
          <div class="col-5">
            <p class="text-lg">: {{ transaction.waybill.waybill_type.translations[0].name }}</p>
          </div>

          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.order_no') }}</p>
          </div>

          <div class="col-5">
            <p class="text-lg font-weight-boldest">: {{ transaction.id }}</p>
          </div>

          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.product_name') }}</p>
          </div>

          <div class="col-5">
            <p class="text-lg">: {{ transaction.id }}</p>
          </div>

          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.product_color') }}</p>
          </div>

          <div class="col-5">
            <p class="text-lg">: {{ transaction.product_name }} </p>
          </div>

          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.supplier_company') }}</p>
          </div>

          <div class="col-5">
            <p class="text-lg">: {{ transaction.waybill.supplier_company.name }}</p>
          </div>

          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.amount') }}</p>
          </div>
          <div class="col-5">
            <p class="text-lg">: {{ parseInt(transaction.tare_amount) }} {{ transaction.amount_unit }} </p>
          </div>

          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.net_amount') }}</p>
          </div>
          <div class="col-5">
            <p class="text-lg">: {{ parseInt(transaction.net_amount) }} {{ transaction.amount_unit }}</p>
          </div>
        </div>
        <div v-if="Number(transactionType) === 0" class="row col-5">
          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.product_identification') }}</p>
          </div>
          <div class="col-5">
            <p class="text-lg">: {{
                Number(transaction.action_type) === 1 ? $t('waybill.by_scanning_qr_code') : $t('waybill.by_entering_info')
              }}</p>
          </div>

          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.entry_net_amount') }}</p>
          </div>
          <div class="col-5">
            <p class="text-lg">: {{ parseInt(transaction.net_amount) }}</p>
          </div>

          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.entry_number_of_roll') }}</p>
          </div>
          <div class="col-5">
            <p class="text-lg">: {{ parseInt(transaction.fabric_roll) }}</p>
          </div>

          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.entry_part_number') }}</p>
          </div>
          <div class="col-5">
            <p class="text-lg">: {{ transaction.part_number }}</p>
          </div>

          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.document_no') }}</p>
          </div>
          <div class="col-5">
            <p class="text-lg">: {{ transaction.document_number }}</p>
          </div>

          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.number_of_roll') }}</p>
          </div>
          <div class="col-5">
            <p class="text-lg">: {{ parseInt(transaction.fabric_roll) }}</p>
          </div>

          <div class="col-7">
            <p class="text-lg font-weight-bold">{{ $t('waybill.part_number') }}</p>
          </div>
          <div class="col-5">
            <p class="text-lg">: {{ transaction.part_number }}</p>
          </div>
        </div>

        <div class="col-2 mt-3 text-center">
          <qrcode-vue :size="150" :value="transaction.qr_link" class="mb-5"
                      level="H"></qrcode-vue>
          <div class="d-block">{{ transaction.code }}</div>
        </div>

        <!--        <div class="col-12 d-flex justify-content-between content-between mt-2 mb-5 px-0">
                                <span
                                    class="px-3 py-0 navy-blue-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm navy-blue-border-disabled-1px text-disabled-navy-blue"
                                >
                                  <span v-html="getIconByKey('icons.waybill.history', {
                                          class: 'w-20px h-20px object-cover d-inline-block opacity-45',
                                    })">
                                  </span>
                                  {{ $t('waybill.entry_transaction_history').toUpperCase() }}
                                </span>
                  <span
                      class="px-3 py-0 navy-blue-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm navy-blue-border-disabled-1px text-disabled-navy-blue">
                                  <span v-html="getIconByKey('icons.waybill.history', {
                                          class: 'w-20px h-20px object-cover d-inline-block opacity-45',
                                    })">
                                  </span>
                                  {{ $t('waybill.exit_transaction_history').toUpperCase() }}
                                </span>
                </div>-->

        <div class="col-12 d-flex justify-content-between content-between mt-2 mb-5 px-0  pb-5 bottom-border-2px-navy">
          <span
              class="flex-grow-1 px-3 py-1 green-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm mr-2"
              @click="$router.push({name: transactionType === 0 ? 'waybill.exit.edit' : 'waybill.entry.edit', params: {id: transaction.waybill.id }})"
          >
            <span v-html="getIconByKey('icons.waybill.edit_2', {
                    class: 'w-20px h-20px object-cover d-inline-block opacity-75',
              })">
            </span>
            {{ $t('waybill.edit').toUpperCase() }}
          </span>
          <span
              class="flex-grow-1 px-3 py-1 green-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm mr-2">
            <span @click="deleteWaybill(transaction.waybill.id)" v-html="getIconByKey('icons.waybill.delete_2', {
                    class: 'w-20px h-20px object-cover d-inline-block opacity-75',
              })">
            </span>
            {{ $t('waybill.delete_waybill').toUpperCase() }}
          </span>
          <a
              :href="transaction.waybill.waybill_pdf_link"
              class="flex-grow-1 px-3 py-1 green-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm mr-2 text-navy-blue"
              target="_blank">
            <span v-html="getIconByKey('icons.waybill.pdf', {
                    class: 'w-20px h-20px object-cover d-inline-block opacity-75',
              })">
            </span>
            {{ $t('waybill.create_pdf').toUpperCase() }}
          </a>
          <a :href="transaction.waybill.waybill_excel_link"
             class="flex-grow-1 px-3 py-1 green-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm text-navy-blue"
             target="_blank">
            <span v-html="getIconByKey('icons.waybill.press_tag', {
                    class: 'w-20px h-20px object-cover d-inline-block opacity-75',
              })">
            </span>
            {{ $t('waybill.create_excel').toUpperCase() }}
          </a>
          <a :href="transaction.waybill_tag_pdf_link"
             class="flex-grow-1 px-3 py-1 green-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm text-navy-blue"
             target="_blank">
            <span v-html="getIconByKey('icons.waybill.press_tag', {
                    class: 'w-20px h-20px object-cover d-inline-block opacity-75',
              })">
            </span>
            {{ $t('waybill.print_label').toUpperCase() }}
          </a>
        </div>
      </div>
    </div>
    <div v-if="transactions !== null && transactions.length === 0" class="card-body card-body-scroll">
      <div class="d-flex justify-content-center">
        <span class="text-primary font-weight-boldest">{{ $t('waybill.there_is_no_previous_transactions') }}</span>
      </div>
    </div>

    <div
        class="cursor-pointer absolute d-flex justify-content-center"
        style="bottom: 0; right: 500px; margin-left: -17px; "
        @click="closeModalByName('waybill-transaction-history')">
      <span v-html="getIconByKey('icons.waybill.cancel', {
              class: 'w-35px h-35px object-cover d-inline-block opacity-75'
        })">
      </span>
    </div>
  </div>
</template>

<script>
import {DELETE_ITEM_BY_ID, GET_ITEMS} from "@/core/services/store/REST.module";
import QrcodeVue from "qrcode.vue";
import moment from "moment";
import {LARAVEL_DATE_FORMAT, LARAVEL_DATE_TIME_FORMAT} from "@/core/config/constant";


export default {
  name: "WaybillTransactionHistoryModal",
  props: {
    transactionType: {
      required: true,
    },
    waybillTransactionId: {
      required: true,
    }
  },
  components: {
    QrcodeVue,
  },
  data() {
    return {
      transactions: null,
      amount_unit: null,
    }
  },
  computed: {
    totalAmount() {
      let total = 0;
      this.transactions.forEach(item => {
        if (item.waybill.transaction_type == 1) {
          total += Number(item.net_amount);
        } else {
          total -= Number(item.net_amount);
        }
      });

      return total;
    },
    totalIncome() {
      let total = 0;
      this.transactions.forEach(item => {
        if (item.waybill.transaction_type == 1) {
          total += Number(item.net_amount);
        }
      });

      if (this.transactions) {
        //total = _.sumBy(this.transactions, 'net_amount');
      }
      return total;
    },
    waybill() {
      return this.transactions.find(item => item && item.hasOwnProperty('waybill'));
    }
  },
  mounted() {
    let self = this;
    this.$store.dispatch(GET_ITEMS, {
      url: 'api/waybill/' + this.waybillTransactionId + '/history',
      filters: {
        transaction_type: this.transactionType,
      },
      showLoading: false,
      acceptPromise: true,
    }).then((result) => {
      if (result.status) {
        let results = [];
        self.transactions = result.data;
        self.transactions.forEach(item => {
          item.created_at = moment(item.created_at, LARAVEL_DATE_TIME_FORMAT)
          item.waybill_date = moment(item.waybill.waybill_date, LARAVEL_DATE_FORMAT)
          item.document_number = item.waybill.document_number
          item.amount_unit = item.unit.code
          this.amount_unit = item.unit.code;
          results.push(item)
        })
        self.transactions = results;
      } else {
        self.sweetAlertError(self.$t('general.cause_error_please_try_again'))
      }
    })

  },
  methods: {
    deleteWaybill(id) {
      let self = this;
      this.sweetAlertConfirm(this.$t('general.are_you_sure')).then(result => {
        if (result) {
          self.sweetAlertLoading();
          self.$store.dispatch(DELETE_ITEM_BY_ID, {
            url: `api/waybills/${id}`,
            id: id,
          }).then(response => {
            if (response.status) {
              self.sweetAlertSuccess(self.$t('general.successfully_deleted')).then(() => {
                self.$router.go(-1);
              })

            } else {
              self.sweetAlertError(self.$t('general.internal_error'));
            }
          })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
